import * as React from 'react';
import { useTranslation } from '@dreamplan/i18n';
import Link from 'next/link';
import { ContactInfo } from '../../types/CommonTypes';
import { Text } from '../Typography';

interface ContactProps {
  className: string;
  info: ContactInfo;
}

const Contact = (props: ContactProps) => {
  const { className, info } = props;
  const { address, email, phone } = info;
  const { t } = useTranslation('navigation');
  return (
    <div className={className}>
      <article className="flex flex-col gap-y-1.5 pb-4">
        <Link
          href={`http://maps.google.com/?q=1200 ${address} DK`}
          className="flex items-center"
          target="_blank"
          rel="noreferrer"
          aria-label={t('map_location_aria_label') + ` ${address}`}
        >
          <Text variant="large" className="min-w-20">
            {t('address_label')}
          </Text>
          <span className="text-gray-250 text-lg">{address}</span>
        </Link>
        <Link
          href={`mailto:${email}`}
          className="flex items-center"
          aria-label={t('send_email_aria_label') + ` ${email}`}
        >
          <Text variant="large" className="min-w-20">
            E-mail
          </Text>
          <span className="text-gray-250 text-lg">{email}</span>
        </Link>
        <Link
          href={`tel:${phone.replace(' ', '')}`}
          className="flex items-center"
          aria-label={t('call_us_aria_label') + ` ${phone}`}
        >
          <Text variant="large" className="min-w-20">
            {t('phone_label')}
          </Text>
          <span className="text-gray-250 text-lg">{phone}</span>
        </Link>
        <article className="flex items-center">
          <Text variant="large" className="min-w-20">
            CVR
          </Text>
          <span className="text-gray-250 text-lg">40965874</span>
        </article>
      </article>
    </div>
  );
};

export default Contact;
