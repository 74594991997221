import * as React from 'react';
import { useTranslation } from '@dreamplan/i18n';
import Link from 'next/link';
import { ContactInfo, PageLinkType, SocialInfo } from '../../types/CommonTypes';
import Contact from '../Contact';
import { LicencesInformation, SupportedLocales } from '../HeaderWhite';
import Logo from '../Logo';
import PageLink from '../PageLink';
import { Socials } from '../Socials/SocialsLogo';
import { Text } from '../Typography';

export interface FooterProps {
  links?: PageLinkType[];
  info: ContactInfo;
  socials: Omit<SocialInfo, 'app'>;
}

const Footer = (props: FooterProps) => {
  const { links = [], info, socials } = props;
  const { lang } = useTranslation('navigation');

  const { link, text, header } = LicencesInformation[(lang || 'en') as SupportedLocales];
  return (
    <footer className="bg-tertiary w-full px-6 py-8 text-white lg:pb-24">
      <div className="mx-auto mt-4 grid max-w-7xl grid-cols-1 gap-y-2 md:gap-8 md:space-y-0 lg:mt-14 lg:grid-cols-3 lg:gap-y-0">
        <div className="order-none space-y-4 lg:pl-8">
          <Logo fill={'white'} className="mb-5 h-16 w-20 lg:-mt-0 lg:h-20 lg:w-24" />
          <div className="hidden lg:block lg:pt-12">
            <Socials info={info} socials={socials} />
          </div>
        </div>
        <div className="order-last block items-center space-x-3 lg:hidden">
          <Socials info={info} socials={socials} />
        </div>
        <div className=" order-1 block lg:hidden">
          <Contact info={info} className="pt-5" />
        </div>

        <div className="order-3 pr-16 pt-10 lg:order-none lg:-ml-20 lg:pt-0">
          <Text variant="paragraph" className="pb-5">
            {header[0]}
          </Text>
          <Contact info={info} className="hidden lg:block" />
          <Text className="text-gray-250 -mt-4 lg:mt-9">
            {text[0] + ' '}
            <Link href={link[0]} target={'_blank'} rel="noreferrer" className="underline">
              FTID 35166
            </Link>
            {' ' + text[1] + ' '}
            <Link href={link[1]} target={'_blank'} rel="noreferrer" className="underline">
              FTID 43055
            </Link>
            . &copy; Copyright Dreamplan.io
          </Text>
        </div>
        <div className="order-2 flex space-y-2 pt-5 lg:order-none lg:pl-16 lg:pr-8 lg:pt-0 xl:pl-32">
          <ul className="w-96 max-w-full space-y-1">
            {links.map((link, index) => (
              <li key={index}>
                <PageLink link={link} className="py-0.5 text-lg text-white " />
                <hr className="border-t border-gray-700" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
