export const LicencesInformation = {
  en: {
    header: ['Dreamplan.io Aps'],
    text: ['Dreamplan is registered at the Financial Supervisory Authority with', 'and'],
    link: [
      'https://virksomhedsregister.finanstilsynet.dk/virksomhed-under-tilsyn-en.html?v=D811E19F-A82A-ED11-A2DF-005056907186',
      'https://virksomhedsregister.finanstilsynet.dk/virksomhed-under-tilsyn-en.html?v=AAB0DB3D-A82A-ED11-A2DF-005056907186',
    ],
  },
  da: {
    header: ['Dreamplan.io Aps'],
    text: ['Dreamplan er registreret hos Finanstilsynet, under', 'og'],
    link: [
      'https://virksomhedsregister.finanstilsynet.dk/virksomhed-under-tilsyn.html?v=D811E19F-A82A-ED11-A2DF-005056907186',
      'https://virksomhedsregister.finanstilsynet.dk/virksomhed-under-tilsyn.html?v=AAB0DB3D-A82A-ED11-A2DF-005056907186',
    ],
  },
};

export type SupportedLocales = keyof typeof LicencesInformation;
