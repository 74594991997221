'use client';
import * as React from 'react';
import { mainPages } from '@dreamplan/constants';
import { useTranslation } from '@dreamplan/i18n';
import { useAuth } from '@dreamplan/shared-ui/client/auth';
import { useFeatureFlag } from '@dreamplan/shared-ui/client/feature-flag';
import { AppLocale } from '@dreamplan/types/src/EdgeStore';
import { Footer, Navbar } from '@dreamplan/ui';
import { Pages } from '@dreamplan/ui/types/Locales';
import { socialInfo } from '../../constants/info';
import { info } from '../../constants/locales/ContactInfo';
import { otherPages } from '../../constants/locales/OtherPages';
import { Info } from '../../types/localeTypes';

type LayoutProps = {
  children: React.ReactNode;
  className: string;
  locale: string;
};

const TopNavigationBar: React.FC<{ locale: string }> = ({ locale }) => {
  const mainPageLinks = mainPages[locale as keyof Pages];
  const otherPageLinks = otherPages[locale as keyof Pages];
  const { value: supportedLocales } = useFeatureFlag<AppLocale[]>('SupportedLocales');
  const { user } = useAuth();

  const { t } = useTranslation('navigation');

  return (
    <Navbar
      links={{
        main: mainPageLinks,
        other: otherPageLinks,
        app: socialInfo.app('sign-up', locale === 'da' ? undefined : locale),
      }}
      auth={{
        signIn: {
          text: t('sign_in_text'),
          link: t('sign_in_link'),
        },
        signUp: {
          text: t('sign_up_text'),
          link: t('sign_up_link'),
        },
        signOut: {
          text: t('log_out_text'),
          link: t('log_out_link'),
        },
        user:
          user.type === 'loading'
            ? { type: 'loading' }
            : user.type === 'signed_in'
            ? {
                type: 'signed-in',
                name: user.data.name ?? '',
                email: user.data.email ?? '',
                isGuest: user.data.guest ?? false,
              }
            : { type: 'unsigned' },
      }}
      locales={supportedLocales ?? []}
    />
  );
};

const Layout = ({ children, className, locale }: LayoutProps) => {
  const otherPageLinks = otherPages[locale as keyof Pages];
  const contactInfo = info[locale as keyof Info];

  return (
    <div className={className}>
      <div className="absolute inset-x-0 top-0 z-50">
        <TopNavigationBar locale={locale} />
      </div>
      <main className="3xl:m-auto 3xl:w-9/12 grow px-8 font-sans text-zinc-800 lg:px-24 2xl:px-32">
        {children}
      </main>
      <Footer links={otherPageLinks} info={contactInfo} socials={socialInfo} />
    </div>
  );
};

export default Layout;
